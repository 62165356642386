import { useParams } from 'react-router-dom';
import { useState } from 'react';
import useAppNavigation from '@shared/lib/hooks/useAppNavigation';
import { useGetPortfolioQuery } from '@shared/api/portfolio';
const useMarketplaceList = () => {
    const params = useParams();
    const [isSidebarOpen, setIsSidebarOpen] = useState(true);
    const [portfolio, selectPortfolio] = useState();
    const { routes, navigate } = useAppNavigation();
    const { isLoading } = useGetPortfolioQuery({
        portfolioId: params.selectedPortfolio || '',
    }, {
        skip: !params.selectedPortfolio,
    });
    const openSidebar = (selectedPortfolio) => {
        selectPortfolio(selectedPortfolio);
        navigate(routes.MARKETPLACE_LIST, {
            params: {
                selectedPortfolio: selectedPortfolio.id,
            },
        });
        setIsSidebarOpen(true);
    };
    const closeSidebar = () => {
        navigate(routes.MARKETPLACE_LIST);
        setIsSidebarOpen(false);
    };
    return {
        isLoading,
        isSidebarOpen,
        openSidebar,
        closeSidebar,
        portfolio,
        selectPortfolio,
    };
};
export default useMarketplaceList;
